<template>
  <div class="container mt-4">
    <div class="row mt-4">
      <div class="col">
        <button v-if="freshAlarm === -1" class="btn btn-primary float-end" @click="addAlarm()">Lisa uus</button>
        <h2>Alarmid</h2>
        <!-- Alarm filters -->
        <div class="alert alert-primary" role="alert">
          <!-- Available groups as filters -->
          <span v-for="group in $store.state.groups" :key="group.id" class="badge me-2 mb-2" :style="'background-color: ' + group.color + ';'">
          <div class="form-check py-1">
            <input class="form-check-input" type="checkbox" v-model="selectedGroups" :value="group.id" :id="'group-select-' + group.id">
            <label class="form-check-label pt-1" :for="'group-select-' + group.id">{{ group.name }}</label>
          </div>
        </span>
          <!-- Checkbox for ungropued alarms -->
          <span class="badge bg-light text-dark me-2">
          <div class="form-check py-1">
            <input class="form-check-input" type="checkbox" v-model="selectedGroups" :value="0" id="group-select-0">
            <label class="form-check-label pt-1" for="group-select-0">Grupeerimata</label>
          </div>
        </span>
          <!-- Quick filter actions -->
          <div class="mt-2">
            <button class="btn btn-sm btn-light text-dark me-2" @click="allFilters" v-if="$store.state.groups.length + 1 > selectedGroups.length">Vali kõik</button>
            <button class="btn btn-sm btn-light text-dark me-2" @click="noFilters" v-if="selectedGroups.length > 0">Puhasta valik</button>
          </div>
        </div>
        <!-- Alarm list -->
        <div class="accordion" id="alarmAccordion">
          <!-- Alarms loop -->
          <div class="accordion-item" v-for="alarm in filtered" :key="alarm.id">
            <h2 class="accordion-header" :id="'heading-' + alarm.id">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#alarm-' + alarm.id" aria-expanded="true" :aria-controls="'alarm-' + alarm.id">
                <!-- Alarm state -->
                <!-- Alarm ok -->
                <i class="fas fa-check text-success" v-if="alarm.state === 0 && alarm.active"></i>
                <!-- Alarm not activated -->
                <i class="fas fa-check text-secondary opacity-25" v-if="alarm.state === 0 && !alarm.active"></i>
                <!-- Alarm high and active -->
                <i class="fas fa-exclamation-triangle text-danger" title="Alarm on aktiivne" v-if="alarm.state === 1 && alarm.active"></i>
                <!-- Alarm high but inactive -->
                <i class="fas fa-exclamation-triangle opacity-25" title="Alarm on aktiivne" v-if="alarm.state === 1 && !alarm.active"></i>
                <!-- Missing data -->
                <i class="fas fa-exclamation-triangle text-warning" title="Andmed puuduvad" v-if="alarm.state === -1"></i>
                <!-- Alarm groups -->
                <div class="float-end ms-2">
                  <span v-for="group in orderBy(alarm.groups_ids, 'group')" :key="group" class="badge me-1" :style="'background-color: ' + groupInfo(group).color + ';'">{{ groupInfo(group).short }}</span>
                </div>
                {{ alarm.name }} <span v-if="alarm.description">- {{ alarm.description }}</span>
                <span class="badge bg-warning ms-2" v-if="alarm.unsaved">MUUDATUSED SALVESTAMATA</span>
                <!-- Raised at -->
                <div v-if="alarm.state === 1">
                  Häires alates: {{ moment(parseInt(alarm.raised_at)).format("DD.MM.YYYY HH:mm") }}
                </div>
                <!-- Missing since -->
                <div v-if="alarm.state === -1">
                  <small>Ühendus puudub alates: {{ moment(parseInt(alarm.raised_at)).format("DD.MM.YYYY HH:mm") }}</small>
                </div>
              </button>
            </h2>
            <div :id="'alarm-' + alarm.id" class="accordion-collapse collapse" :class="{'show': alarm.id === undefined}" :aria-labelledby="'heading-' + alarm.id" data-bs-parent="#alarmAccordion">
              <div class="accordion-body">
                <!-- Active -->
                <div class="row mb-2">
                  <div class="col">
                    <div class="alert" :class="{'alert-success': alarm.active, 'alert-warning': !alarm.active}" role="alert">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="alarm.active" @change="setUnsaved(alarm.id)" :id="'active-' + alarm.id">
                        <label class="form-check-label" :for="'active-' + alarm.id">
                          Aktiveeritud<br>
                          <small v-if="!alarm.active">Mitte-aktiivse alarmi kohta ei saadeta teavitusi</small>
                          <small v-if="alarm.active">Aktiivse alarmi kohta saadetakse reaalajas teavitusi</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Name, desc and type -->
                <div class="row">
                  <!-- Name -->
                  <div class="col-12 col-md-4 mb-2">
                    <label :for="'name-' + alarm.id" class="form-label">Nimi</label>
                    <input type="text" class="form-control" :id="'name-' + alarm.id" v-model="alarm.name" @change="setUnsaved(alarm.id)" placeholder="Alarmi nimi">
                  </div>
                  <!-- Description -->
                  <div class="col-12 col-md-4 mb-2">
                    <label :for="'desc-' + alarm.id" class="form-label">Kirjeldus</label>
                    <input type="text" class="form-control" :id="'desc-' + alarm.id" v-model="alarm.description" @change="setUnsaved(alarm.id)" placeholder="Alarmi kirjeldus">
                  </div>
                  <!-- Type -->
                  <!--
                  <div class="col-12 col-md-4 mb-2">
                    <label :for="'type-' + alarm.id" class="form-label">Tüüp</label>
                    <select class="form-select" v-model="alarm.type_id" :id="'type-' + alarm.id" @change="setUnsaved(alarm.id)">
                      <option value="0">Olek rakendumispiirist suurem</option>
                      <option value="1">Olek rakendumispiirist väiksem</option>
                    </select>
                  </div>
                  -->
                </div>
                <!-- Divider -->
                <hr>
                <!-- Alarm configuration -->
                <div class="row">
                  <!-- Setpoint -->
                  <div class="col-12 col-md-3 mb-2">
                    <label :for="'setpoint-' + alarm.id" class="form-label">Rakendumispiir</label>
                    <div class="input-group">
                      <input type="number" class="form-control" :id="'setpoint-' + alarm.id" v-model="alarm.setpoint" @change="setUnsaved(alarm.id)" placeholder="Alarmi rakendumispiir">
                      <span class="input-group-text" v-if="alarm.unit !== null">{{ alarm.unit }}</span>
                    </div>
                  </div>
                  <!-- Delay ON -->
                  <div class="col-12 col-md-3 mb-2">
                    <label :for="'delayon-' + alarm.id" class="form-label">Rakendumise viivitus</label>
                    <div class="input-group">
                      <input type="number" class="form-control" :id="'delayon-' + alarm.id" v-model="alarm.delay_on" @change="setUnsaved(alarm.id)">
                      <span class="input-group-text">sekundit</span>
                    </div>
                  </div>
                  <!-- Delay OFF -->
                  <div class="col-12 col-md-3 mb-2">
                    <label :for="'delayoff-' + alarm.id" class="form-label">Taastumis viivitus</label>
                    <div class="input-group">
                      <input type="number" class="form-control" :id="'delayoff-' + alarm.id" v-model="alarm.delay_off" @change="setUnsaved(alarm.id)">
                      <span class="input-group-text">sekundit</span>
                    </div>
                  </div>
                  <!-- Repeat interval -->
                  <!--
                  <div class="col-12 col-md-3 mb-2">
                    <label :for="'repeat-' + alarm.id" class="form-label">Teavituse intervall</label>
                    <div class="input-group">
                      <input type="number" class="form-control" :id="'repeat-' + alarm.id" v-model="alarm.repeat_interval" @change="setUnsaved(alarm.id)">
                      <span class="input-group-text">minutit</span>
                    </div>
                  </div>
                  -->
                </div>
                <!-- Divider -->
                <hr>
                <!-- Groups -->
                <div class="row">
                  <!-- Groups -->
                  <div class="col-12 col-md-3 mb-2">
                    Teavitatud grupid
                    <!-- Groups list -->
                    <div v-for="group in $store.state.groups" :key="group.id">
                      <span class="badge" v-if="alarm.groups_ids.includes(group.id)" :style="'background-color: ' + group.color + ';'">{{ group.name }}</span>
                    </div>
                  </div>
                  <!-- Notification sender -->
                  <!--
                  <div class="col-12 col-md-6 mb-2">
                    <label :for="'sender-' + alarm.id" class="form-label">Teavituse saatja</label>
                    <select class="form-select" v-model="alarm.sender_id" :id="'sender-' + alarm.id" @change="setUnsaved(alarm.id)">
                      <option v-for="sender in senders" :key="sender.id" :value="sender.id">{{ sender.name }}</option>
                    </select>
                  </div>
                  -->
                </div>
                <!-- Divider -->
                <hr>
                <!-- Save changes -->
                <div class="row mt-4">
                  <div class="col">
                    <div class="d-grid gap-2">
                      <!-- Show when adding new alarm -->
                      <div v-if="alarm.id === undefined">
                        <button class="btn btn-outline-secondary btn-lg" type="button" @click="cancelAdd()">Tühista</button>
                        <button class="btn btn-primary btn-lg ms-2" :disabled="alarm.name === undefined || alarm.name === ''" type="button" @click="createAlarm()">Salvesta alarm</button>
                      </div>
                      <!-- Show when editing existing alarm -->
                      <div v-else>
                        <button class="btn btn-outline-danger btn-sm" type="button" @click="removeAlarm(alarm.id)">Eemalda alarm</button>
                        <button class="btn btn-primary btn-lg ms-2 float-end" :disabled="!alarm.unsaved" type="button" @click="saveAlarm(alarm.id)">
                          <span v-if="alarm.unsaved">Salvesta muudatused</span>
                          <span v-else>Salvestatud</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End alarms loop -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      selectedGroups: [],
      alarms: [],
      filtered: [],
      senders: []
    }
  },
  watch: {
    alarms: function () {
      this.allFilters()
    },
    selectedGroups: function () {
      this.filtered = []
      // console.log('Filter', this.selectedGroups)
      // Loop over alarms
      this.alarms.forEach((alarm) => {
        if (alarm.groups_ids.length === 0 && this.selectedGroups.includes(0)) {
          this.filtered.push(alarm)
        } else {
          // Loop over selected groups
          this.selectedGroups.forEach((group) => {
            if (this.filtered.findIndex(obj => obj.id === alarm.id) === -1) {
              if (alarm.groups_ids.includes(group)) {
                // console.log('Alarm ' + alarm.name + ' has group ' + group)
                this.filtered.push(alarm)
              }
            }
          })
        }
      })
      // console.log(this.filtered)
    }
  },
  mounted () {
    // Get alarms and senders from DB
    this.getAlarms()
    this.getSenders()
  },
  methods: {
    // Set row as unsaved
    setUnsaved (id) {
      const alarmIndex = this.alarms.findIndex(obj => obj.id === id)
      this.$set(this.alarms[alarmIndex], 'unsaved', true)
    },
    // Map all existing groups in store to simple array
    allFilters () {
      this.selectedGroups = this.$store.state.groups.map(obj => obj.id)
      this.selectedGroups.push(0)
    },
    // Clear all filters
    noFilters () {
      this.selectedGroups = []
    },
    // Return group info based on ID
    groupInfo (id) {
      const group = this.$store.state.groups.find(obj => obj.id === id)
      if (group === undefined) {
        return {}
      } else {
        return group
      }
    },
    // Add new alarm to local memory
    addAlarm () {
      this.filtered.unshift({})
    },
    // Get alarms from DB
    getAlarms () {
      axios.get(this.$store.state.eksekoApi + '/getAlarmList')
        .then((response) => {
          // console.log(response.data)
          this.alarms = response.data
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    // Save updated alarm in DB
    saveAlarm (id) {
      const _this = this
      const token = localStorage.getItem('localToken')
      axios.post(this.$store.state.eksekoApi + '/updateAlarm', this.alarms.find(obj => obj.id === id), { headers: { Authorization: `${token}` } })
        .then(function (response) {
          console.log(response.data)
          const alarmIndex = _this.alarms.findIndex(obj => obj.id === id)
          _this.alarms[alarmIndex].unsaved = false
          _this.$swal({ icon: 'success', title: 'Salvestatud', showConfirmButton: false, timer: 1500 })
        })
        .catch(function (error) {
          console.log(error)
          _this.$swal({ icon: 'error', title: 'Tekkis viga', showConfirmButton: false, timer: 1500 })
          _this.getAlarms()
        })
    },
    // Delete alarm from DB
    removeAlarm (id) {
      const alarm = this.alarms.find(obj => obj.id === id)
      this.$swal({
        title: 'Oled kindel?',
        text: 'Eemalda alarm "' + alarm.name + '"?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Jah, eemalda!',
        cancelButtonText: 'Katkesta'
      }).then((result) => {
        if (result.isConfirmed) {
          const _this = this
          const token = localStorage.getItem('localToken')
          axios.post(this.$store.state.eksekoApi + '/deleteAlarm', { id: id }, { headers: { Authorization: `${token}` } })
            .then(function (response) {
              // console.log(response.data)
              _this.alarms = _this.alarms.filter(obj => obj.id !== id)
              _this.$swal('Valmis!', 'Alarm "' + alarm.name + '" eemaldati', 'success')
            })
            .catch(function (error) {
              console.log(error)
              _this.getAlarms()
            })
        }
      })
    },
    // Create new alarm in DB
    createAlarm () {
      const _this = this
      const token = localStorage.getItem('localToken')
      axios.post(this.$store.state.eksekoApi + '/insertAlarm', this.filtered.find(obj => obj.id === undefined), { headers: { Authorization: `${token}` } })
        .then(function (response) {
          console.log(response.data)
          _this.alarms = _this.alarms.filter(obj => obj.id !== undefined)
          _this.$swal({ icon: 'success', title: 'Uus alarm lisatud', showConfirmButton: false, timer: 1500 })
          _this.getAlarms()
        })
        .catch(function (error) {
          console.log(error)
          _this.$swal({ icon: 'error', title: 'Tekkis viga', showConfirmButton: false, timer: 1500 })
          _this.getAlarms()
        })
    },
    // Cancel adding new alarm
    cancelAdd () {
      this.filtered = this.filtered.filter(obj => obj.id !== undefined)
    },
    // Get sender names from DB
    getSenders () {
      const token = localStorage.getItem('localToken')
      axios.get(this.$store.state.eksekoApi + '/getSenders', { headers: { Authorization: `${token}` } })
        .then((response) => {
          // console.log(response.data)
          this.senders = response.data
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    // Find array index for fresh alarm that has not been saved
    freshAlarm: function () {
      return this.filtered.findIndex(obj => obj.id === undefined)
    }
  }
}
</script>
